@import "styles/_mixins.scss";
.imageCard{
  --fg: var(--black);
  --bg: var(--white);
  --imageGradient: linear-gradient(#ffffff00 50%, #ffffff11 100%);

  &.light{
    --fg: var(--white);
    --bg: var(--blue);
    --imageGradient: linear-gradient(#00000000 50%, #00000044 100%);
  }

  position: relative;

  display: flex;
  flex-direction: column;
  overflow: hidden;

  font-weight: inherit;
  text-decoration: inherit;

  border: var(--borderWidth) solid var(--bg);
  box-shadow: none;

  transition: box-shadow var(--speed1) ease-in-out;


  user-select: none;

  .image{
    position: relative;

    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 14rem;
    max-height: 100%;
    overflow: hidden;

    border: var(--borderWidth) solid var(--fg);
    .imageInner{
      position: relative;

      width: 100%;
      height: 100%;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;

        filter: var(--dulledImage);

        transition: filter 0.1s ease-in-out, transform 0.1s ease-in-out;
      }

      &::after{
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: var(--imageGradient);

        content: "";
      }
    }

    .tag{
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 2;

      padding: var(--spacing3) var(--spacing4);

      color: var(--bg);
      font-weight: 400;

      font-size: var(--fontSize1);
      text-transform: uppercase;

      background-color: var(--fg);
    }
  }
  .body{
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    height: 100%;

    color: var(--fg);
    font-weight: 400;

    background: var(--bg);

    transition: all 0.2s ease-in-out;

    .inner{
      padding: var(--spacing6) var(--spacing5);

      line-height: var(--lineHeight);

      .label{
        /*stylelint-disable-next-line value-no-vendor-prefix*/
        display: -webkit-box;

        max-width: 100%;

        margin-bottom: var(--spacing4);
        overflow: hidden;

        font-weight: 700;

        font-size: var(--fontSize3);

        text-transform: capitalize;
        text-overflow: ellipsis;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;

        >*{
          display: inline;
        }
        svg{
          display: inline;
          flex-shrink: 0;
          width: auto;
          height: 1em;
          min-height: 1em;
          margin-right: var(--spacing3);
        }
      }
    }
  }

  &.overlaid{
    .image{
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      .tag{
        position: absolute;
        top: 0;
        left: 0;

        height: min-content;
      }
      &::after{
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;

        width: 100%;
        height: 100%;

        background: linear-gradient(#00000000 0%, #000000aa 100%);

        content: "";
      }
    }
    .body{
      justify-content: flex-end;
      margin-top: var(--spacing10);

      color: var(--white);

      background-color: transparent;
      >*{
        position: relative;
        z-index: 2;
      }
    }
  }
}

a.imageCard{
  box-shadow: var(--elevation2);

  &:hover{
    box-shadow: var(--elevation4);
    cursor: pointer;
    .image img{
      transform: scale(1.05, 1.05);
      filter: var(--normalImage);
    }
  }
}
