.description{
  line-height: var(--lineHeight);
}

.price{
  margin-top: var(--spacing4);

  font-weight: 700;
}

.editButton{
  margin-top: var(--spacing4);
}
