.searchBar{
  --fg: var(--white);

  &.light{
    --fg: var(--black);
  }

  position: relative;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: stretch;
  box-sizing: border-box;

  width: 100%;
  height: 4.25rem;
  padding: 0 var(--spacing3) 0 var(--spacing5);

  font-size: var(--fontSize3);

  background-color: transparent;
  border: 1px solid var(--fg);

  svg{
    height: 1em;
    margin-right: var(--spacing5);
    path{
      fill: var(--fg);
    }
  }

  input{
    width: 100%;
    height: 100%;

    color: var(--fg);

    font-size: var(--fontSize3);

    font-family: var(--primaryFont);

    background-color: transparent;

    border: none;

    &:focus{
      outline: none;
    }

    &::placeholder{
      color: #888888;
    }
  }


  .buttonWrapper{
    position: absolute;
    top: 0;
    right: 0;

    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    padding: 0 var(--spacing4);
    @media (max-width: 700px){
      display: none;
    }
  }

  &:hover,
  &:focus-within{
    border-color: var(--green);
    > svg{
      path{
        fill: var(--green);
      }
    }
  }
}
