div.tag,
a.tag{
  --fg: var(--black);
  --bg: var(--grayLight);

  &.light{
    --fg: var(--white);
    --bg: var(--black);
  }

  padding: var(--spacing3) var(--spacing3);
  text-decoration: none;
  

  color: var(--fg);
  font-weight: 700;
  font-size: var(--fontSize1);
  line-height: 1em;
  white-space: nowrap;

  background-color: var(--bg);
  border: none;
  border-radius: var(--curve);
  box-shadow: none;

  transition: color var(--speed1) ease-in-out, background-color var(--speed1) ease-in-out, box-shadow var(--speed1) ease-in-out;

  user-select: none;

  &::selection{
    color: var(--bg);

    background-color: var(--fg);
  }

  &.clickable:hover{
    box-shadow: var(--elevation2);
    cursor: pointer;
    --fg: var(--white);
    --bg: var(--green);
  }
}
