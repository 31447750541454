section.fold{
  min-height: 70vh;
  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
  }
  h1{
    color: var(--white);
    text-align: center;
    @media (max-width: 800px){
      margin-bottom: var(--spacing6);
    }
    @media (max-width: 450px){
      margin-bottom: var(--spacing5);
    }
  }

  .subheadline{
    display: flex;
    flex-wrap: wrap;
    gap: var(--spacing4);
    justify-content: center;
  }

  .search{
    box-sizing: border-box;
    width: 100%;
    margin-top: var(--spacing7);
    padding: var(--spacing7);

    background-color: #ffffff22;
    @media (max-width: 800px){
      grid-template-columns: repeat(2, 1fr);
      padding: var(--spacing6);
    }

    .results{
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: var(--spacing6);
      margin-top: var(--spacing6);
      @media (max-width: 1300px){
        grid-template-columns: repeat(2, 1fr);
      }
      @media (max-width: 800px){
        grid-template-columns: repeat(1, 1fr);
      }
    }


    .alert{
      font-weight: 700;
      font-size: var(--fontSize3);
    }

    .pitch{
      margin: var(--spacing5) 0;
    }
    .button{
      margin-top: var(--spacing6);
    }
  }
}
section.useCases{
  h2{
    text-align: center;
  }
  .subheadline{
    text-align: center;
  }
  .cases{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacing6);
    @media (max-width: 1300px){
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 650px){
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
section.buyPitch{
  > div{
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing7);
    @media (max-width: 850px){
      grid-template-columns: repeat(1, 1fr);
      gap: var(--spacing7);
      .imageCell{
        display: none;
      }
    }
    h2{
      text-align: center;
    }


    >div{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .image{
        width: 100%;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
        >*{
          height: 100%;
        }
      }
      ul{
        margin-top: 0;
        margin-bottom: var(--spacing7);
      }
    }
  }
}

section.featured{
  h2{
    text-align: center;
  }
  .subheadline{
    text-align: center;
  }
  .prompts{
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacing6);

    >:first-child{
      grid-row: span 2;
      grid-column: span 2;
    }
    @media (max-width: 1300px){
      grid-template-columns: repeat(3, 1fr);
      >:first-child{
        grid-row: span 1;
      }
    }
    @media (max-width: 900px){
      grid-template-columns: repeat(2, 1fr);
    }
    @media (max-width: 650px){
      grid-template-columns: repeat(1, 1fr);
      >:first-child{
        grid-column: span 1;
      }
    }
  }
}


section.sellPitch{
  > div{
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(2, 1fr);
    gap: var(--spacing7);
    @media (max-width: 850px){
      grid-template-columns: repeat(1, 1fr);
      gap: var(--spacing7);
      .imageCell{
        display: none;
      }
    }

    h2{
      text-align: center;
    }

    >div{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .image{
        width: 100%;
        height: 100%;
        img{
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
        >*{
          height: 100%;
        }
      }
      ul{
        margin-top: 0;
        margin-bottom: var(--spacing7);
      }
    }
  }
}

section.news{

  >div{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .subheadline{
    text-align: center;
  }
  .cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: var(--spacing5);
  }
}

.categories{
  h2{
    text-align: center;
  }


  .grid{
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: var(--spacing7);
    @media (max-width: 1000px){
      grid-template-columns: repeat(3, 1fr);
    }
    @media (max-width: 700px){
      grid-template-columns: repeat(2, 1fr);
    }

    .category{
      display: flex;
      flex-direction: column;
      align-items: center;

      color: var(--black);
      text-decoration: none;
      .icon{
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        width: 4rem;
        height: 4rem;
        padding: var(--spacing5);

        background-color: var(--blue);
        border-radius: 50%;

        transition: background-color var(--speed1) ease-in-out;
        svg{
          width: 100%;
          height: 100%;
          *{
            fill: var(--white);
          }
        }
      }
      .label{
        margin-top: var(--spacing3);

        font-weight: 700;
        font-size: var(--fontSize3);
        text-align: center;
        @media (max-width: 600px){
          font-size: var(--fontSize2);
        }
      }

      .description{
        max-width: 250px;
        margin-top: var(--spacing3);

        text-align: center;
      }

      &:hover{
        cursor: pointer;
        .icon{
          background-color: var(--green);
        }
      }
    }
  }
}

