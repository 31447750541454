.header{
  --navFg: var(--white);
  --navBg: var(--blue);
  --navOpenBg: var(--blue);
  --navOpenFg: var(--white);

  $navCollapseWidth: 1000px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  display: flex;
  justify-content: center;

  width: 100%;
  height: var(--navHeight);

  background-color: var(--navBg);
  box-shadow: var(--elevation4);

  transition: background-color 0.1s ease-in-out;

  user-select: none;

  .sectionInner{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding-top: 0;
    padding-bottom: 0;
  }

  .logo{
    position: relative;
    z-index: 101;

    display: flex;
    align-items: center;

    height: 100%;
    margin: 0 0 0 calc(-1 * var(--spacing5));
    padding: 0 var(--spacing5);

    transition: background-color var(--speed1) ease-in-out;

    svg{
      width: auto;
      height: 30px;

      filter: brightness(1);

      transition: filter var(--speed1) ease-in-out;
      @media (max-width: 600px){
        height: 20px;
      }
    }
  }


  .menu{
    position: relative;

    display: flex;
    height: 100%;

    .links{
      display: flex;
      flex-direction: row;
      align-items: flex-end;
      @media (max-width: $navCollapseWidth){
        flex-direction: column;
        flex-grow: 1;
      }
      .navItem{
        display: flex;
        flex-grow: 1;
        align-items: center;
        box-sizing: border-box;
        width: auto;
        height: 100%;
        padding: var(--spacing5) var(--spacing5);

        color: var(--navFg);
        font-weight: 400;
        font-family: var(--primaryFont);

        letter-spacing: 0.05em;
        text-transform: capitalize;
        text-decoration: none;

        cursor: pointer;

        transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

        user-select: none;

        svg{
          height: 1.5rem;

          background-color: transparent;
          path{
            transition: fill 0.1s ease-in-out;

            fill: var(--navFg);
          }
        }

        &:hover,
        &.active{
          color: var(--navBg);

          background-color: var(--navFg);

          svg{
            path{
              fill: var(--navBg);
            }
          }
        }
        @media (min-width: $navCollapseWidth){
          &.secondary{
            padding: var(--spacing3) var(--spacing4);

            font-size: var(--fontSize1);

            filter: grayscale(100%);
            &:last-child{
              margin-right: calc(var(--spacing5) - var(--spacing4));
            }
          }
        }
      }
      @media (max-width: $navCollapseWidth){
        position: fixed;
        top: var(--navHeight);
        left: 0;
        z-index: 100;

        flex-direction: column;
        width: 100vw;
        height: calc(100% - 4rem);

        pointer-events: none;


        .navItem{
          position: relative;
          z-index: 1;

          justify-content: center;
          width: 100vw;
          margin-bottom: -1px;
          padding: 0;

          font-size: var(--fontSize4);

          background-color: var(--navOpenBg);
          border-top: 1px solid var(--gray);
          border-bottom: 1px solid var(--gray);
          box-shadow: 1px 1px var(--black);
          transform: translate(100%, 0);
          svg{
            width: auto;
            height: 2.5rem;
          }
        }
      }
    }

    .burger{
      position: relative;
      z-index: 101;

      background-color: transparent;
      border: none;
      svg{
        path{
          fill: var(--navFg);
        }
      }
      @media (min-width: $navCollapseWidth){
        display: none;
      }
    }
  }

  &.open .menu .links{
    pointer-events: all;
  }
}
