.form{
  position: relative;

  display: flex;
  flex-direction: column;

  grid-row: 1/span 2;
  gap: var(--spacing5);
  justify-self: end;
  width: 100%;
  max-width: 450px;

  picture{
    position: absolute;
    top: 0;
    left: 0;
    z-index: -10;
  }

  .main{
    position: relative;
    z-index: 1;

    display: flex;
    flex-direction: column;
  }

  .tos{
    font-size: var(--fontSize0);
  }
}

.info{
  display: grid;
  gap: var(--spacing8);
  margin-bottom: auto;
  @media (max-width: 768px){
    margin-top: var(--spacing9);
  }

  .entry{
    display: flex;
    align-items: center;


    svg{
      margin-right: var(--spacing7);
    }

    .entryContent{
      .entryKey{
        margin-bottom: var(--spacing5);

        font-weight: 700;
      }
    }
  }

  .entry:nth-child(1){
    animation-delay: 0.6s;
  }
  .entry:nth-child(2){
    animation-delay: 0.7s;
  }
  .entry:nth-child(3){
    animation-delay: 0.8s;
  }
  .entry:nth-child(4){
    animation-delay: 0.9s;
  }
  .entry:nth-child(5){
    animation-delay: 1s;
  }
}

.pulsingImage{
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;
  img{
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    max-width: 500px;
    height: 100%;

    animation: pulsing-image 4s ease-in-out infinite alternate;
  }
  @keyframes pulsing-image{
    0%{
      transform: scale(0.95, 0.95);
    }
    100%{
      transform: scale(1, 1);
    }
  }
}

