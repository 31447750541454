.button{
  --foreground: var(--white);
  --background: var(--green);
  --foregroundHover: var(--white);
  --backgroundHover: var(--greenLight);

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: min-content;


  &.iconOnly{
    --foreground: var(--black);
  }

  &.danger{
    --foreground: var(--black);
    --background: var(--danger);
    --foregroundHover: var(--black);
    --backgroundHover: var(--dangerLight);
  }


  &.grayscale{
    --foreground: var(--black);
    --background: var(--gray19);
    --foregroundHover: var(--gray19);
    --backgroundHover: var(--black);
  }


  &.ghost{
    --foreground: var(--green);
    --background: transparent;
    --foregroundHover: var(--white);
    --backgroundHover: var(--green);

    &.danger{
      --foreground: var(--danger);
      --background: transparent;
      --foregroundHover: var(--white);
      --backgroundHover: var(--danger);
    }

    &.grayscale{
      --foreground: var(--white);
      --background: transparent;
      --foregroundHover: var(--black);
      --backgroundHover: var(--white);
    }


    *{
      box-shadow: none;
    }
    &:focus,
    &:active{
      box-shadow: none;
    }

    button,
    a{
      border: 1px solid var(--foreground);
    }
  }

  .notice{
    margin-top: var(--spacing1);

    color: var(--background);

    font-size: var(--fontSize1);
    text-align: center;
  }


  button,
  a{
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    height: 2.75rem;
    padding: 0 var(--spacing6);

    color: var(--foreground);

    font-weight: 700;
    font-size: var(--fontSize2);
    font-style: normal;
    line-height: 1em;
    white-space: nowrap;

    text-decoration: none;

    background-color: var(--background);
    border: none;

    border-radius: var(--curve);
    outline: none;
    box-shadow: var(--elevation2);
    cursor: pointer;

    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out, filter 0.1s ease-in-out, box-shadow 0.1s ease-in-out;

    user-select: none;

    -webkit-tap-highlight-color: transparent;


    svg{
      width: auto;
      height: 1.5rem;
      margin: 0 var(--spacing3) 0 0;
      path{
        transition: fill 0.1s ease-in-out;

        fill: var(--foreground);
      }

      &:last-child{
        margin: 0 0 0 var(--spacing3);
      }
    }

    &:hover:not([disabled]){
      color: var(--foregroundHover);

      background-color: var(--backgroundHover);
      box-shadow: var(--elevation4);

      svg path{
        fill: var(--foregroundHover);
      }
    }

    &:focus,
    &:active{
      box-shadow: none;
      filter: brightness(90%);
    }
    &[disabled]{
      box-shadow: none;
      cursor: default;
      filter: grayscale(1);

      & ~ .notice{
        filter: grayscale(1);
      }
    }
  }


  &.iconOnly{
    a,
    button{
      width: 2.75rem;
      padding: var(--spacing3);

      border: none;

      svg{
        width: 100%;
        height: 100%;
      }


      &:hover:not([disabled]){
        color: var(--foreground);

        background-color: var(--background);
        box-shadow: none;

        svg path{
          fill: var(--foregroundHover);
        }
      }
    }
  }

  &.large{
    a,
    button{
      height: 3.75rem;
      padding: 0 var(--spacing8);

      font-size: var(--fontSize3);
    }

    .notice{
      font-size: var(--fontSize2);
    }
  }
}
